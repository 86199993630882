


@font-face {
  font-family: "Power Grotesk UltBd";
  src: url("../fonts/PowerGrotesk-UltraBold.eot");
  src: url("../fonts/PowerGrotesk-UltraBold.eot") format("embedded-opentype"),
    url("../fonts/PowerGrotesk-UltraBold.woff2") format("woff2"),
    url("../fonts/PowerGrotesk-UltraBold.woff") format("woff"),
    url("../fonts//PowerGrotesk-UltraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../fonts/MabryPro-Bold.eot");
  src: url("../fonts/MabryPro-Bold.eot") format("embedded-opentype"),
    url("../fonts//MabryPro-Bold.woff2") format("woff2"),
    url("../fonts/MabryPro-Bold.woff") format("woff"),
    url("../fonts//MabryPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'defiworld-Regular';
  src: url('../fonts/defiworld-Regular.eot');
  src: url('../fonts/defiworld-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/defiworld-Regular.woff2') format('woff2'),
      url('../fonts/defiworld-Regular.woff') format('woff'),
      url('../fonts/defiworld-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "defiworld-Bold";
  src: url('../fonts/defiworld-Bold.eot');
  src: url('../fonts/defiworld-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/defiworld-Bold.woff2') format('woff2'),
      url('../fonts/defiworld-Bold.woff') format('woff'),
      url('../fonts/defiworld-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "defiworld-Bold1";
  src: url('../fonts/defiworld-Bold1.eot');
  src: url('../fonts/defiworld-Bold1.eot?#iefix') format('embedded-opentype'),
      url('../fonts/defiworld-Bold1.woff2') format('woff2'),
      url('../fonts/defiworld-Bold1.woff') format('woff'),
      url('../fonts/defiworld-Bold1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.btn-primary {
  --cui-btn-hover-bg: #00FFFF;
  --cui-btn-hover-color: #231f20;
  --cui-btn-hover-border-color: #00FFFF;
  --cui-btn-active-border-color: #00FFFF;
  --cui-btn-focus-shadow-rgb: #00FFFF;
}

.btn {
  --cui-btn-hover-border-color: #fff;
}

.newstake {
  font-size: 14px !important;
  border-radius: 5px !important;
}

.tagbtn {
  margin-top: -30px;
  margin-bottom: 37px;
}
.clearbtn {
  padding: 8px 40px;
  border-radius: 12px;
  border:1px solid #00FFFF;
  color: #fff;
  font-weight: 600;
  background-color: transparent;
}
.filterclass{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  align-items: center;
 
  .zebarea,.usdtarea{
    position: relative;
    button{
      padding: 8px 40px;
      border-radius: 12px;
      background: #00FFFF;
      color: #000;
      font-weight: 600;
      border: transparent;

    }
    .filterbox{
      position: absolute;
      left: 50%;
      min-width: 150px;
      display: none;
      /* width: 150px; */
      transform: translateX(-50%);
      top: calc(100% + 9px);
    }
  }

  .filterbox{
    background-color: #2a2930;
    padding: 10px 15px;
    border-radius: 7px;
  }
  label{
    margin-right: 10px;
    width: fit-content;
    input{
      margin-right: 7px;
    }
  }
}

body {
  font-size: 14px;
  line-height: 1.4;
  color: #ffffff;
  position: relative;
  font-family: "defiworld-Regular";
  font-weight: 400;
  overflow-x: hidden;

  // background: url(../images/vv3.gif);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-attachment: fixed;
  // background-size: contain;
  // overflow-x: hidden;
  &::before {
    content: "";
    position: absolute;
    background-color: rgb(0, 0, 0);
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

// loader-css
.loaderx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 99;
}

.loader__layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
}

.loader__container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // color: $color_3;
  z-index: 101;
}

.loader__container__counter {
  font-size: 170px;
  font-weight: 700;
  margin: 0;
}

.loader__container__loading {
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 10px;
}

.box {
  position: absolute;
  width: 100vw;
  text-align: center;

  .text-reveal {
    position: relative;
    overflow: hidden;
    font-weight: 200;
    text-transform: uppercase;
    font-size: 10rem;
    

    span {
      display: inline-block;
    }
  }
}

.text-reveal {
  h2 {
    text-align: start;
    font-size: 3rem;
    text-shadow: 2px 2px 50px #50dbff;
  }
}

.animation-2{
  h4{
    text-align: start;
    font-size: 20px;
    font-family: 'defiworld-Regular', sans-serif;
    font-weight: 300;

    
  }
}
.headersec {
  display: flex;
  justify-content: space-between;
}



.btn{
  color: #fff;
  &:hover{
    color: #00FFFF;
  }
}
.buttonGroup {

  .btn1,
  .btn2,
  .btn3 {
    align-items: center;
    background-image: url(../images/btnbg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: inline-flex;
    font-size: 15px;
    font-weight: 800;
    justify-content: center;
    letter-spacing: 0.8px;
    min-height: 91px;
    min-width: 180px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    background-position: center;
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:hover {
      color: #00FFFF !important;
    }
  }
}


.bannersec {
  padding: 120px 0;
  margin-top: 50px;
}


.buttonGroup2 {

  .btn1,
  .btn2,
  .btn3 {
    align-items: center;
    background-image: url(../images/btnbg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: inline-flex;
    font-size: 15px;
    font-weight: 800;
    justify-content: center;
    letter-spacing: 0.8px;
    min-height: 91px;
    min-width: 180px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    background-position: center;
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:hover {
      color: #00FFFF !important;
    }
  }
}
@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bgBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.flex {
  display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // margin: 20px auto;
}

.col-6 {
  flex-basis: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  flex-basis: 0 0 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "defiworld-Bold";
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.01em;
}

.btnMU,
.btn-btnMU {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  border: transparent;
  --cui-btn-active-bg: transparent;
  border: 1px solid #00FFFF;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: -4px;
  //   left: -4px;
  //   height: calc(100% + 8px);
  //   width: calc(100% + 8px);
  //   background: linear-gradient(60deg,
  //       #ffb46a,
  //       #ff9984,
  //       #ff84a7,
  //       #f09eff,
  //       #97baff,
  //       #7bedff,
  //       #93fff1,
  //       #a1ffb8);
  //   border-radius: 6px;
  //   z-index: -1;
  //   animation: animatedgradient 0.9s ease alternate infinite;
  //   background-size: 300% 300%;
  // }

  span {
    display: block;
    padding: 0px 12px;
    font-family: "defiworld-Regular";
  }
}

// .btn-btnMU:nth-child(1) {
  // &::after {
    // animation: unset;
  // }
// }

// .btn-btnMU:nth-child(2) {
  // &::after {
    // animation: unset;
  // }
// }

// .btn-btnMU:nth-child(3) {
  // &::after {
    // animation: unset;
  // }
// }

// .btn-btnMU:nth-child(4) {
  // &::after {
    // animation: unset;
  // }
// }

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.spincoin {
  // width: 25px;
  // height: 25px;
  max-width: 25px;
  height: auto;
  // margin: 0 5px 0px 0;
}

img {
  height: auto;
  width: 100%;
}

.gifbox {
  max-width: 330px;
  margin: auto;
}

a {
  color: #83c8ff;
  outline: none;

  &:hover {
    color: #00FFFF;
  }
}

#root {
  min-height: 100vh;
}

// main {
  // padding: 40px 0px;
  // width: 80%;
  // height: 80%;
  // margin: 0 auto;
  // min-height: 1000px;
  // padding: 130px 5vw 5vw;
  // position: relative;
// }

.borderboxmain {
  display: block;
  height: 100%;
  left: 0;
  min-height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  img {
    display: block;
    height: auto;
    object-fit: contain;
    position: absolute;
    width: 97vw;
  }
}

.borderboxmain img.linetopleft {
  left: 0;
  object-position: left;
  top: 0vw;
  width: 100%;
}

.borderboxmain img.linebottomright {
  bottom: 1vw;
  object-position: right;
  right: 0px;
  width: 100%;
}

// Header Css Start
header {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  // box-shadow: 0px 0px 70px -30px #8b8b8b;
  // background-color: #020315;
  // background-image: linear-gradient(#020315, #02031500 100%);
  z-index: 2;

  .header {
    padding: 0px 0px;
    min-height: auto;
    background-color: transparent;
    border: none;
    position: relative;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   z-index: -1;
    //   background-color: #020314;
    //   filter: blur(30px);
    // }
  }

  .header-brand {
    img {
      width: 100%;
      max-width: 200px;
    }
  }

  .header-nav {
    a {
      padding: 0px 10px;
      text-transform: uppercase;
      text-decoration: none;

      &.active {
        color: #ffbebe;
      }
    }
  }

  .bgBody {
    position: absolute;
  }

  // .logoMainHead {
  //   width: 60px;
  // }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  z-index: 0;
  position: relative;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  border: none !important;
}

.MuiFormControl-root {

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }

  .MuiOutlinedInput-root.Mui-focused,
  .MuiOutlinedInput-root:hover {
    fieldset {
      border-color:  hsl(199.38, 71.03%, 56.51%);
    }
  }
}

.MuiInputBase-root {
  fieldset {
    border-color: hsl(199.38, 71.03%, 56.51%);
  }

  input {
    color: #fff;
  }

  .MuiInputBase-input {
    color: #fff;
  }

  .MuiSvgIcon-root {
    color: #fff;
  }
}

.backsidebtn {
  background: #000000;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #FFF;
}

/* this is a recreation of twitter search in css */
.form {
  --input-text-color: #fff;
  --input-bg-color: #110f21;
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #372d14;
  --width-of-input: 200px;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}

/* form style */
.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
  // margin: 0 auto;
}

/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
}

.search,
.close-btn {
  position: absolute;
}

/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}

/* svg -- size */
svg {
  width: 17px;
  display: block;
}

/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--active-color);
  opacity: 0;
  visibility: hidden;
}

/* styling of input */
.input {
  color: var(--input-text-color);
  width: 100%;
  margin-inline: min(2em, calc(var(--inline-padding-of-input) + var(--gap)));
  background: none;
  border: none;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: var(--text-color);
}

/* input background change in focus */
.input:focus~.fancy-bg {
  border: 1px solid var(--active-color);
  background: var(--focus-input-bg-color);
}

/* search icon color change in focus */
.input:focus~.search {
  fill: var(--active-color);
}

/* showing close button when typing */
.input:valid~.close-btn {
  opacity: 1;
  visibility: visible;
}

/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.incometable {
  background-color: #000000;
  border: 1px solid #110f21;

  button {
    color: #fff;
  }

  td {
    color: #fff !important;
    font-family: "Inter", sans-serif;
  }
  .poolbtn{
    padding: 6px 12px;
    background: #251e54;
    color: #fff;
    border-radius: 12px;
    border: transparent;
    box-shadow: 2px 2px 3px #2e2929;
  }

  th {
    color: #fff !important;
    font-family: "Inter", sans-serif;
  }
}

.incometittle {
  font-family: "Inter", sans-serif !important;
  background: #110f21;
  border: 1px solid #110f21;


  tr {
    th {
      color: #fff;
      font-size: 17px;
      font-family: "Inter";
    }
  }
}

// Header Css End

// Footer Css Start
footer {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 55px;
  // box-shadow: 0px 0px 70px -30px #8b8b8b;

  .copyright {
    color: #ffffff;
  }

  .footer {
    background-color: transparent;
    border: none;
    padding: 60px 0px 15px;
    position: relative;
    overflow: hidden;
  }

  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #83c8ff;
      border: 1px solid #83c8ff;
      border-radius: 3px;

      &:hover {
        border-color: #ffbebe;
        color: #00FFFF;
      }
    }
  }
}

.footer_bottom {
  padding: 20px 0;
  margin: 0 auto;
}

.copyright_right {
  p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #aaa6b9;
    font-weight: 500;
    text-transform: uppercase;
  }

  a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }
}

.footer_social {
  ul {
    margin-bottom: 0px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 10px;
    padding-right: 20px;
    position: relative;

    &::before {
      position: absolute;
      content: "||";
      right: 0;
      top: 50%;
      transform: translatey(-50%);
    }

    &:last-child::before {
      content: none;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    text-align: center;

    &:hover {
      svg {
        fill: #00FFFF;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }
}
.copyright_right {
  margin-left: 60px;
}
.twittericon{
  img{

    max-width: 15px;
  }
}

// Footer Css End

// Banner Css Start
.topInformation {
  margin-bottom: 30px;

  h1 {
    font-size: 52px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.3;
    font-family: "Bebas Neue";
    margin-bottom: 30px;
    text-align: left;
    color: #3c2900;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff,
      0 0 2px #00FFFF, 0 0 2px #00FFFF, 0 0 0 #00FFFF, 0 0 2px #00FFFF,
      0 0 11px #00FFFF;

    .nftxt {
      color: rgb(158, 236, 255);
    }
  }

  .btnMU,
  .btn-btnMU {
    font-size: 14px;
    // min-width: 200px;
  }
}

.nftMain {
  padding-bottom: 40px;
  background: #000;
}

.nftmainimg {
  img {
    max-height: 700px;
  }
}

.listViewBx {
  border-radius: 6px;
  position: relative;
  margin: 90px auto;

  // &::before {
  //   content: "";
  //   position: absolute;

  //   top: -2px;
  //   left: -2px;
  //   height: calc(100% + 4px);
  //   width: calc(100% + 4px);
  //   background: linear-gradient(60deg,
  //       #ffb46a,
  //       #ff9984,
  //       #ff84a7,
  //       #f09eff,
  //       #97baff,
  //       #7bedff,
  //       #93fff1,
  //       #a1ffb8);
  //   border-radius: 6px;
  //   z-index: -1;
  //   animation: animatedgradient 3s ease alternate infinite;
  //   background-size: 300% 300%;
  // }
  .refbox {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .refclaimbtn {
    border: none;
    border-radius: 40px;
    background: linear-gradient(to right,
        #00FFFF,
        #00FFFF,
        #00FFFF,
        #00FFFF,
        #00FFFF);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    color: #231f20;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    z-index: 2;
    // transition-duration: 3s;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
    background-size: 200% 200%;
    padding: 8px 30px;
    overflow: hidden;
    margin-inline: 10px;

    &::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 100%;
      background-image: linear-gradient(120deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0) 70%);
      top: 0;
      left: -100px;
      opacity: 0.6;
    }

    &:hover {
      transform: scale(1.05) !important;
      filter: brightness(1.2);
      // transition-duration: 3s;
      // animation: animatedgradient 5s ease infinite;
      // background-position: right;
    }

    &:hover::before {
      animation: shine 1.5s ease-out infinite;
    }
  }

  .inrInfoBx {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid #00FFFF;
    padding: 10px;
    border-radius: 10px;
    background: #000;
    position: relative;
    height: 100%;

    .imginrBx {
      position: relative;
      flex: 0 0 50%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      max-height: 100px;
    }

    p {
      font-size: 19px;
      font-family: "defiworld-Regular", sans-serif;
    }

    strong {
      font-size: 31px;
      display: block;
      font-family: "Bebas Neue";
    }

    img {
      margin: 0 auto 0px;
      display: block;
      position: relative;
      animation: zoominout 3s alternate infinite;
      max-width: 100%;
      height: 100%;
      width: auto;
    }

    @keyframes zoominout {

      0%,
      100% {
        transform: scale(1) rotate(-5deg);
      }

      50% {
        transform: scale(0.95) rotate(0);
      }
    }
  }
}

.coinbox {
  max-width: 500px;
}

.lazyload-wrapper {
  margin: 10px auto;

  video {
    transform: scale(1);
    mix-blend-mode: lighten;
  }
}

.lazyload-wrapper svg {
  mix-blend-mode: difference;
}

// Banner Css End

// nft Balance Css Start
.nftBalance {
  .balanceViewBx {
    .balancewrap {
      margin-bottom: 30px;
      border-radius: 0px;
      box-shadow: 0px 0px 100px -80px #ffffff;
      border-radius: 6px;
      height: 100%;
      position: relative;
      display: block;
      text-align: center;
      // background: url(../images/nfts/Lvl1.gif);
      opacity: 1;
      border: 1px solid #020004;
      padding: 20p;
      background-size: contain;
      background-color: #000;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        border-radius: 6px;
        background-size: 300% 300%;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(1px);
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        background: linear-gradient(60deg,
            #ffb46a,
            #ff9984,
            #ff84a7,
            #f09eff,
            #97baff,
            #7bedff,
            #93fff1,
            #a1ffb8);
        border-radius: 6px;
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
      }
    }

    .balancelist:first-child {
      border-radius: 6px 6px 0 0px;
    }

    .balancelist:last-child {
      border-radius: 0px 0px 6px 6px;
    }

    .balancelist {
      position: relative;
      padding: 10px 20px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      background: rgba(58, 47, 47, 0.23);

      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: -2px;
      //   left: -2px;
      //   height: calc(100% + 4px);
      //   width: calc(100% + 4px);
      //   background: linear-gradient(
      //     60deg,
      //     #ffb46a,
      //     #ff9984,
      //     #ff84a7,
      //     #f09eff,
      //     #97baff,
      //     #7bedff,
      //     #93fff1,
      //     #a1ffb8
      //   );
      //   border-radius: 6px;
      //   z-index: -1;
      //   animation: animatedgradient 3s ease alternate infinite;
      //   background-size: 300% 300%;
      // }

      span {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-size: 16px;
      }

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      svg {
        margin-left: 10px;
        width: 22px;
        height: auto;
        display: inline-block;
        cursor: pointer;
      }
    }

    .gifbox {
      max-width: 120px;
      margin: 12px auto;
      z-index: 999;
      position: relative;
      // background: #cf95433d;
      background: rgb(58 47 47 / 46%);
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// nft Balance & List Css End

// ournft
.newstake {
  .leftinfo {
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }
}

.nftMain {
  .nftheadline {
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    // text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
    //   0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
    animation: pulsate 1.5s infinite alternate;
    
    font-weight: 400;
  }

  .leftinfo {
    display: flex;
    padding: 6px 0px;
    font-family: "Inter", sans-serif;

    .highlight {
      padding-left: 8px;
      padding-right: 6px;
      border-radius: 4px;
      font-size: 22px;
      color: #00FFFF;
      font-weight: 700;
    }
  }

  .labeltag {
    font-size: 16px;
    opacity: 0.7;
    margin-right: 8px;
  }

  .labelamount {
    font-size: 16px;
    opacity: 1;
    color: #fff;
    font-weight: 700;
  }

  .boxbtn1 {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;

    &:disabled {
      opacity: 0.5;
    }
  }

  // .bgclaim{
  //   background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  //   color: rgb(121, 103, 3);
  //   font-weight: 600;
  // }

  .rightinfo {
    display: flex;
    justify-content: center;
    padding: 0 10px;

    .labelpercent {
      color: hsl(180, 100%, 50%);
      font-weight: 700;
      font-size: 24px;
      margin: 4px 0;
    }
  }

  .stakebtnarea {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;

    .inputvalue {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 90%;
    }

    span {
      font-size: 20px;
      font-family: "defiworld-Regular";

      font-weight: 500;
      color: #fff;
      display: block;
    }
  }

  a {
    color: #e3e3e3;
    text-decoration: none;
  }

  .stakebtn {
    // background-color: #676767;
    padding: 5px 16px;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
    z-index: 9;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
    position: relative;
    width: 62%;
    margin-bottom: 25px;
    border: 1px solid #00FFFF ;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: -4px;
    //   left: -4px;
    //   height: calc(100% + 8px);
    //   width: calc(100% + 8px);
    //   background: linear-gradient(60deg,
    //       #ffb46a,
    //       #ff9984,
    //       #ff84a7,
    //       #f09eff,
    //       #97baff,
    //       #7bedff,
    //       #93fff1,
    //       #a1ffb8);
    //   border-radius: 6px;
    //   z-index: -1;
    //   background-size: 300% 300%;
    // }
  }

  .popular_gaming_inner {
    .col-3 {
      flex-basis: 0 0 25%;
      max-width: 25%;
    }

    .swiper-button-prev {
      background-color: #00FFFF;
      cursor: pointer;
      height: 50px;
      left: 0;
      position: absolute;
      top: 45%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 50px;
      z-index: 9999999;

      &::before {
        align-items: center;
        content: "↞";
        display: flex;
        font-size: 36px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .swiper-button-next {
      background-color: #00FFFF;
      cursor: pointer;
      height: 50px;
      position: absolute;
      right: 0;
      top: 45%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 50px;
      z-index: 9999999;

      &::before {
        align-items: center;
        content: "↠";
        display: flex;
        font-size: 36px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .notearea {
      text-align: justify;
      max-width: 600px;
    }

    .nftsvideo {
      // border: 1px solid #686868;
      border-radius: 10px;
      position: relative;
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      // background-color: #000;
      cursor: pointer;
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
      // border: 2px solid #b4892a;
      // height: 651px;

      :disabled {
        opacity: 0.5;
        box-shadow: none;

        &:hover {
          opacity: 0.5;
          box-shadow: none;
        }
      }

      .itembox {
        flex: 0 0 50%;
      }
    }

    .reasultPageSwiper {
      justify-content: center;
    }

    .nftsvideo1 {
      // border: 1px solid #686868;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      border-radius: 0px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transform-origin: right bottom;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
      background: rgb(38 38 40 / 70%);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      padding: 40px 25px;
      border: 1px solid #00ffff4a;

      .leftinfo {
        justify-content: space-between;
      }

      // border: 2px solid #b4892a;
      // height: 651px;

      :disabled {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: none;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
          box-shadow: none;
        }
      }

      .itembox {
        flex: 0 0 50%;
      }
    }

    .leftinfo {
      .labeltag1 {
        font-size: 20px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background: url(../images/shape-bg.png);
          height: 100%;
          width: 212px;
          left: -70px;
          background-repeat: no-repeat;
        }

        &::after {
          content: "";
          position: absolute;
          background: url(../images/clock.svg);
          height: 20px;
          width: 100%;
          left: -30px;
          background-repeat: no-repeat;
          top: 5px;
        }
      }
    }

    .cardheading {
      // font-family: ;
      font-size: 30px;
      font-weight: 700;
      color: #fff;
      display: flex;
      justify-content: space-between;
    }

    .plusicon {
      border-radius: 50%;
      margin-right: 10px;
      border: 1px solid white;
      mix-blend-mode: normal;
    }

    .nftboxtitle {
      align-items: center;
      background-color: #686868;
      color: #fff;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      justify-content: center;
      left: -60px;
      padding: 7px 5px;
      position: absolute;
      text-align: center;
      top: 40px;
      -webkit-transform: rotate(-55deg);
      transform: rotate(-55deg);
      width: 200px;
      z-index: 1;
    }

    .swiper-slide {
      overflow: hidden;
    }
  }
}

.stakebtn {
  // background-color: #676767;
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  border: 1px solid #00FFFF;
  background: transparent;
}

// Connect Wallet css Start
.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);

  .modal-dialog {
    max-width: 450px;
  }

  .modal-content {
    color: #ffffff;
    background-color: #0a0a0a;
    box-shadow: 0px 0px 70px -40px #666666;
    padding: 0px 20px;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      background: linear-gradient(60deg,
          #ffb46a,
          #ff9984,
          #ff84a7,
          #f09eff,
          #97baff,
          #7bedff,
          #93fff1,
          #a1ffb8);
      border-radius: 6px;
      z-index: -1;
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }

    .modal-header {
      align-items: flex-start;
      border: none;
      padding: 20px 10px;
    }

    .modal-body {
      padding: 10px;
    }

    button.btn-close {
      outline: none;
      box-shadow: none !important;
      opacity: 1;
      filter: invert(1);
    }

    .modal-title {
      font-size: 24px;
      margin-bottom: 0px;
      font-family: "Bebas Neue";
      color: #00FFFF;
      text-shadow: 0.3px 0.3px 0.3px #111;
      letter-spacing: 0.11em;
      color: #3c2900;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff,
        0 0 2px #00FFFF, 0 0 2px #00FFFF, 0 0 0 #00FFFF, 0 0 2px #00FFFF,
        0 0 11px #00FFFF;

      p {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        margin: 10px 0px 0px;
        text-shadow: none;
      }
    }
  }

  .popupbtngrp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .btngrp {
      flex-basis: calc(33.33% - 15px);
      max-width: calc(33.33% - 15px);
      margin: 0px 7px 20px;
      display: block;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        background: linear-gradient(60deg,
            #ffb46a,
            #ff9984,
            #ff84a7,
            #f09eff,
            #97baff,
            #7bedff,
            #93fff1,
            #a1ffb8);
        border-radius: 6px;
        z-index: 0;
        background-size: 300% 300%;
      }
    }

    button,
    a {
      background-color: #000000;
      // border: 1px solid #aea25d;
      color: blanchedalmond;
      font-size: 16px;
      padding: 20px 8px 20px;
      border-radius: 5px;
      letter-spacing: 1px;
      line-height: 1.1;
      display: block;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;
      font-family: "Bebas Neue";
      border: transparent;

      &:hover {
        border-color: #523664;
      }

      img {
        display: block;
        margin: 0 auto 15px;
        height: 50px;
        width: 50px;
        background-color: rgba(0, 0, 0, 0.169);
        object-fit: contain;
      }

      span {
        display: block;
      }
    }
  }
}

// div#demo {
//   margin-top: 25px;
// }

// Connect Wallet css End
.headingwrapper {
  background-color: #000;
  padding-top: 30px;
}

.informationMU {
  background-color: #000;

  .benefitbox {
    background: #000;
    padding: 25px 30px;
    margin: 15px 0px;
    transition: all 300ms;
    text-align: center;
    height: 100%;
    cursor: pointer;
    border: 1px solid #00FFFF;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      content: "";
      background: url(../images/frame.png);
      height: 44px;
      width: 100%;
      top: 0;
      left: -58px;
      background-repeat: no-repeat;
      filter: hue-rotate(136deg);
    }

    &::after {
      position: absolute;
      content: "";
      background: url(../images/frame.png);
      height: 44px;
      width: 100%;
      bottom: 0;
      right: -58px;
      background-repeat: no-repeat;
      transform: scaleX(-1) scaleY(-1);
      filter: hue-rotate(136deg);
    }

    p {
      color: gray;
    }
  }

  .benefit-icon {
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    width: 100px;
    height: 100px;
    padding: 18px;
    margin: 15px auto 15px;
  }

  .benefitcontent {
    // border: 1px solid dimgrey;
    padding: 20px;
  }

  .infotitle {
    margin-bottom: 30px;

    h2 {
      font-size: 48px;
    }
  }

  .infoimage {
    img {
      max-height: 500px;
    }
  }

  .infotxt {
    h3 {
      font-size: 42px;
      margin-bottom: 15px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      // animation: pulsate 1.5s infinite alternate;
      font-weight: 600;
      font-family: "defiworld-Regular";
    }

    .infoboost {
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-size: 19px;
      line-height: 1.6;
    }

    p {
      letter-spacing: 1px;
      color: #c9c9c9;
      text-align: justify;
      font-size: 15px;

      span {
        color: #ffffff;
        font-weight: 600;
        font-size: 19px;
      }
    }
  }
}

.faqheading {
  font-size: 42px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 600;
  font-family: "Bebas Neue";
}

.account {
  display: flex;
  justify-content: center;
}

.Toastify__progress-bar-theme--light {
  background: #ce86f9;
}

.Toastify__toast {
  color: #000000;
  font-size: 18px;
}

.bgVideoBody {
  object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.1;
  mix-blend-mode: difference;

  &,
  video {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 42px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
    0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
  font-family: "Bebas Neue";

  b {
    display: flex;
    text-align: center;
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 20px;
    margin: 0px 10px;
    border-radius: 6px;
    position: relative;

    span {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: "Sorts Mill Goudy", serif;
    }
  }

  p {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 15px auto 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;

    // span {
    //     display: block;
    // }
  }
}

// loader-css
.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//news section
// .newsbox{
//   position: relative;
//   border: 1px solid #43394d;
//   margin: 0 20px;
//   height: 100%;
//   padding: 30px;
//   border-radius: 10px;
//   background-color: #17092d75;
//   background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.0509803922) 0%, rgba(255, 255, 255, 0) 73.86%);
//   border-style: solid;
//   border-width: 1px 1px 0px 1px;
//   // border-color: rgba(255, 255, 255, 0.1019607843);
//   border-color: rgb(0 0 0 / 10%);
//   border-radius: 16px 16px 0px 0px;
//   width: calc(100% - 20px);
// }
.newsbox {
  position: relative;
  border: 1px solid #43394d;
  margin: 0 20px;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(23, 9, 45, 0.4588235294);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 16px;
  width: calc(100% - 20px);
  box-shadow: inset 0 0 35px 3px rgb(80 50 130);
}

.roundbg {
  position: relative;
  height: 90px;
  width: 120px;
  margin: 0 auto;

  img {
    position: relative;
    filter: drop-shadow(2px 4px 6px black);
  }
}

.label1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(58, 3, 158, 0.3);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 5px 5px 5px #000;
}

.infolabel {
  margin-top: 20px;

  .btnMU,
  .btn-btnMU {
    font-size: 16px;
  }
}

.news,
.nftBalance {
  h3 {
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
      0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
    animation: pulsate 1.5s infinite alternate;
    font-weight: 400;
  }
}

.newssec {
  .alice-carousel__dots-item:not(.__custom) {
    background-color: #ffffff;
    border-radius: 2px;
    height: 15px;
    transform: skew(-10deg);
    width: 15px;
    opacity: 0.1;
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #711dff;
    opacity: 1;
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    display: inline-flex;
    cursor: pointer;
    // padding: 10px;
    margin: 0;
    color: #fff;
    // font-size: 16px;
    // background: #23143b;
    // border-radius: 50%;
    // height: 40px;
    // width: 40px;
    // justify-content: center;
    font-weight: bolder;

    span {
      height: 40px;
      width: 40px;
      justify-content: center;
      border-radius: 50%;
      align-items: center;
      display: flex;
      font-size: 16px;
      background: #23143b;
    }
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: fit-content;
    padding: 10px 5px;
    position: absolute;
    right: 0;
    top: -71px;
  }

  .alice-carousel__prev-btn {
    text-align: right;
    right: 50px;
  }

  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: unset;
  }

  @media (max-width: 576px) {

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      display: none;
    }
  }
}

.coin {
  background-color: #000;
  border-radius: 6px;
  position: relative;
  padding: 15%;
  margin: 0 30%;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: linear-gradient(60deg,
        #ffb46a,
        #ff9984,
        #ff84a7,
        #f09eff,
        #97baff,
        #7bedff,
        #93fff1,
        #a1ffb8);
    border-radius: 6px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }

  .inbx {
    position: absolute;
    margin-top: 0;
  }
}

.infotxt h4 {
  text-align: center;
  margin-bottom: 20px;
}

.inputvalue {
  // margin: 10px;
  // max-width: 460px;
  // margin: 30px auto;

  .value {
    margin: 5px;
    padding: 7px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #fff;
    border: 3px solid #fff;
    border-radius: 7px;
    width: 70%;

    .value:hover {
      border: 3px solid #fff;
    }
  }
}

.MuiTabs-scrollButtonsDesktop {
  display: flex !important;
}

.MuiTabs-flexContainer {
  display: flex;
  // justify-content: center;
}



.MuiTabs-indicator {
  display: none;
}



.tabbar {
  margin-block: 25px;
  justify-content: center;
}

body {
  .MuiBox-root {
    background-color: transparent;
    // margin-top: 100px;
  }
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}

.MuiTabs-scroller {
  .MuiTabs-indicator {
    display: none;
    background-color: transparent;
  }
}

.MuiTabs-scroller .MuiTabs-flexContainer {
  column-gap: 10px;

  button {
    color: #fff;
    border: 1px solid #0a6969;
    border-radius: 6px;
    font-size: 16px;
    max-width: 201px;
    font-family: "Inter", sans-serif;
    // span{
    //   display: none;
    // }
  }

  button.Mui-selected {
    background: linear-gradient(to right, #00FFFF, #00FFFF, #00FFFF, #00FFFF, #00FFFF);
    border: none;
    border-radius: 5px;
    color: #231f20;
    font-weight: 600;
    background-size: 200%;
    border: 1px solid transparent;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
}

.value {
  flex: 0 0 50%;
  border: none;
  outline: none;
  padding: 8px;
  background: transparent;
  border: 1px solid #00FFFF;
  color: #fff;
  font-size: 18px;
  border-radius: 6px;
  padding-right: 50px;
}

.inputwrap {
  position: relative;
}

// .labelspan1{
//   font-size: 26px !important;
//   color: rgb(224 199 62) !important;
// }
.Zebheading {
  font-size: 40px;
  letter-spacing: 0.05em;
  text-align: left;
}

.MuiPaper-root .MuiDialogActions-root {
  .approvebtn:hover {
    background-color: gray;
  }
}

.MuiDialogContent-root {
  .inputvalue {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
  }

  span {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: #fff;
    display: block;
  }

  .leftinfo {
    display: flex;
    padding: 6px 0px;
    font-family: "Inter", sans-serif;
  }

  .labeltag {
    margin-right: 4px;
    opacity: 0.7;
  }

  .labelpercent {
    color: #00FFFF;
    font-weight: 700;
    font-size: 24px;
    margin: 4px 0;
  }

  .inputbox {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0;
  }

  .inputwrap {
    position: relative;
  }

  .maxbtn {
    color: #fff !important;
  }

  .value::placeholder {
    color: #fff;
  }

  .value {
    flex: 0 0 50%;
    border: none;
    outline: none;
    padding: 8px;
    background: transparent;
    border: 1px solid #00FFFF;
    color: #fff;
    font-size: 18px;
    border-radius: 6px;
    padding-right: 50px;
  }

  p {
    color: #fff;
    background: transparent;
  }
}

.maxbtn {
  position: absolute;
  right: 5px;
  top: 50%;
  padding: 5px;
  transform: translate(0%, -50%);
  border-radius: 6px;
  color: hwb(180 0% 0%) !important;
  font-weight: bolder !important;
}

.copy-button {
  // background-color: #f0f0f0;
  border: #fff;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputbar {
  font-size: 16px;
  margin: 0 auto;

  border-radius: 11px;
  margin-top: -7px;
  padding: 5px;
  // border: 2px solid #fff;
  background: #54575a;
  cursor: pointer;
}

.form-select {
  width: 70%;
  display: inline;
  margin: 10px;
  filter: hue-rotate(45deg);

  option {
    background: #000 !important;
    color: #fff;
  }
}

.dilog {
  border: 1px solid #e8e1e1;
  border-radius: 6px;
  margin-left: 25%;
  padding: 8px;
  width: 50%;

  h3 {
    font-family: "Bebas Neue";
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
}

.form-select2 {
  width: 70%;
  display: inline;
  margin: 10px;
  filter: hue-rotate(45deg);
  margin-left: 25%;
  width: 50%;
  padding: 9px;
  border-radius: 6px;

  option {
    padding: 0 !important;
    background: #000 !important;
    color: #fff !important;
  }
}

.inputvalue2 {
  margin: 3px;
}

.stakebtn2 {
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  margin-left: 26%;
  width: 48%;
}

.stakebtn2::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg,
      #ffb46a,
      #ff9984,
      #ff84a7,
      #f09eff,
      #97baff,
      #7bedff,
      #93fff1,
      #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  background-size: 300% 300%;
}

.bgGIF {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -3;
  top: 0;
  left: 0;
  max-width: 100%;
  background-size: cover;
}

.logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // user-select: none;
  // margin-bottom: 30px;
  // text-align: left;
}

.logo b {
  font-size: 50px;
  color: #fee;
}

.inputbox {
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 15px 0;
}

.boxbtn {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  // color: #ffffff;
  // width: 190px;
  font-size: 17px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  // transition: .5s;
  // margin-left: 30px;
  border: none;
  background: linear-gradient(to right, #00FFFF, #00FFFF, #00FFFF, #00FFFF, #00FFFF);
  color: #fff;
  border-radius: 5px;
  font-family: "defiworld-Regular";
  color: #231f20;
  font-weight: 600;
  background-size: 200%;

  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  &:hover {
    transform: scale(1.05) !important;
    filter: brightness(1.2);
    // transition-duration: 3s;
    // animation: animatedgradient 5s ease infinite;
    // background-position: right;
  }

  &:hover::before {
    animation: shine 1.5s ease-out infinite;
  }
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.boxbtn2 {
  align-items: center;
  background-image: url(../images/btnbg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 15px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 91px;
  min-width: 180px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  background-position: center;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  border: none;

  &:hover {
    color: #ffbc05;
  }
}

.wnbtn {
  background-color: #bc4242;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 4px;
}

.mobilebtn {
  display: none;
}

.wnbtn2 {
  background-color: #0763af;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 10px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  margin-top: 10px;
}

.wnbtn2::marker {
  color: white;
}

// -----------------------F&Q section----------------------- //
.card {
  width: 100% !important;
  height: 100%;
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  // flex-basis: calc(50% - 18px);
}

.title {
  font-size: 18px;
  /* text-lg */
  text-align: center;
}

@media (min-width: 768px) {

  /* md: breakpoint */
  .title {
    font-size: 20px;
    /* text-xl */
  }
}

.description {
  color: #e2e8f0;
  /* text-gray-200 */
  text-align: center;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #fff;
  border: 2px solid #5411a1;
  font-size: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  font-family: "Inter", sans-serif;
  box-shadow: inset 0 0 30px #5411a1;
}

.fqbox {
  padding: 22px;
}

.test {
  border-radius: 8px;
  border: 1px solid #5411a1;
  margin: 8px;
  flex: 0 0 calc(33.33% - 20px);
  background-color: #000;
}

.answer {
  padding-top: 8px;
  font-size: 15px;
  color: #b5bfcdae;
}

.boxborder1 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

.boxborder2 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

.boxborder3 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

@keyframes mymove {
  50% {
    border-radius: 50px;
  }
}
.tablearea{
min-height: 100vh;

  .MuiTableContainer-root{
    background-color: #000;
    border: 4px solid #110f21;
  }
}
.nodata {
  min-height: 300px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 4px solid #110f21;
  background: #000;
  color: #fff;
}

.hello {
  .MuiTabs-flexContainer {
    justify-content: center;
  }


  // .Mui-selected {
  //   color: #fff !important;
  //   background: #652fa3 !important;
  // }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.box {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.boxbutton {
  h4 {
    font-size: 10px;
  }

  background-color: red;

  .boxbutton:hover {
    background: red;
  }
}

.boxttn {
  color: #fff;
}

.statushighlight {
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  border-radius: 4px;
  background: #5411a1;
}

.refwrap {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transform-origin: right bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  background: rgba(38, 38, 40, 0.7);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 40px 25px;
  margin: 10px 0;

  .cardheading {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }

  .leftinfo {
    display: flex;
    padding: 6px 0px;
    font-family: "Inter", sans-serif;
    justify-content: space-between;
  }

  .labeltag1 {
    font-size: 20px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: url(../images/shape-bg.png);
      height: 100%;
      width: 212px;
      left: -70px;
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      background: url(../images/clock.svg);
      height: 20px;
      width: 100%;
      left: -30px;
      background-repeat: no-repeat;
      top: 5px;
    }
  }

  .labeltag {
    font-size: 16px;
    opacity: 0.9;
    margin-right: 8px;
  }

  .labelamount {
    font-size: 16px;
    opacity: 1;
    color: #fff;
    font-weight: 700;
    white-space: nowrap;
  }

  .stakebtnarea {
    display: flex;
  }

  .boxbtn1 {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    margin: 0 auto;
  }
}

.myPagination {
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgb(17 15 33);

  button {
    color: #fff;
  }

  button.Mui-selected {
    color: #fff;
    background-color: #242323;

    &:hover {
      background-color: #242323;
    }
  }
}
//admin sec css start
.adminsec{
  margin-bottom: 200px;
  .inrInfoBx {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    padding: 20px;
    border-radius: 10px;
    background: #110f21;
    position: relative;
}
.refbox {
  position: relative;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  max-height: 100px;
  flex-direction: column;
}
}




.btnbanner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .btninfo{
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    font-size: 17px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    border: none;
    background: linear-gradient(to right, #00FFFF, #00FFFF, #00FFFF, #00FFFF, #00FFFF);
    color: #fff;
    border-radius: 5px;
    font-family: "Inter";
    color: #231f20;
    font-weight: 600;
    background-size: 200%;
  }
  .active {
    /* Add styles for the active button */
    background: linear-gradient(to right, #00FFFF, #00FFFF, #00FFFF, #00FFFF, #00FFFF);
    /* Change the styles to reflect the 'active' state */
    color: #fff;
  }
}


//faq section css start
.faq {
  background-color: #000000c4;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 120px;
 
  }

  .accordion {
    --cui-accordion-bg: #283042;
    --cui-accordion-color: #fff;
    --cui-accordion-btn-color: rgba(255, 255, 255, 0.8);
    --cui-accordion-btn-bg: transparent;
    --cui-accordion-btn-icon-width: 0.875rem;
    --cui-border-color: #000000;
  }

  .accordion.accordion-flush {
    margin: 0 auto;
  }

  .accordion-item {
    padding-top: 1px;
    padding-bottom: 1px;
    background: #000000;
    border: 2px solid #00FFFF!important;
    cursor: pointer;
    border-radius: 10px;
  }

  .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none !important;
  }

  .accordion-flush .accordion-collapse {
    border-width: 0;
  }

  .accordion-body {
    padding-top: 0px;

    p {
      color: #fff;
    }
  }

  button.accordion-button {
    font-size: 18px;
  }

  .accordion-flush .accordion-item {
    border: none;
    border-radius: 0;
    margin-block-end: 1rem;
    border-radius: 10px;
    box-shadow: 0.5rem 2px 0.5rem rgba(0, 0, 0, 0.1);
  }

  .accordion-flush .accordion-item .accordion-button,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 5px;
  }

  .accordion-button::after {
    filter: invert(1);
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #ffff;
    box-shadow: none;
  }

  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }

  .title-box {
    font-family: "defiworld-Regular";
    font-weight: 400;
    line-height: 1.25;
    color: #ffffff;
    -webkit-text-stroke: 1.5px #ffffff;
    text-align: center;
    font-size: 45px;
    // animation: shake 0.25s steps(2) infinite;
    margin-bottom: 40px;
  }




  @keyframes shake {
    0% {
      transform: none;
    }

    100% {
      transform: skew(1.5deg, 0.2deg) translate(0, -1.5px);
    }
  }
// loader //

  .loaderMain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .loader2 {
    height: 30px;
    display: inline-block;
  }
  
  .loader2>div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 10px #00FFFF;
    background: #00FFFF;
    float: left;
    margin: 5px;
    transform: scale(2);
  }
  
  .loader2>div:nth-child(1) {
    animation: anm-BL-53-move1 1s infinite linear;
  }
  
  .loader2>div:nth-child(2) {
    animation: anm-BL-53-move2 1s infinite linear;
    animation-delay: 0.2s;
  }
  
  .loader2>div:nth-child(3) {
    animation: anm-BL-53-move3 1s infinite linear;
    animation-delay: 0.3s;
  }
  
  .loader2>div:nth-child(4) {
    animation: anm-BL-53-move4 1s infinite linear;
    animation-delay: 0.4s;
  }
  
  .loader2>div:nth-child(5) {
    animation: anm-BL-53-move5 1s infinite linear;
    animation-delay: 0.5s;
  }
  
  @keyframes anm-BL-53-move1 {
    50% {
      background: #00FFFF;
      transform: scale(1);
    }
  }
  
  @keyframes anm-BL-53-move2 {
    50% {
      background: #00FFFF;
      transform: scale(1);
    }
  }
  
  @keyframes anm-BL-53-move3 {
    50% {
      background: #00FFFF;
      transform: scale(1);
    }
  }
  
  @keyframes anm-BL-53-move4 {
    50% {
      background: #00FFFF;
      transform: scale(1);
    }
  }
  
  @keyframes anm-BL-53-move5 {
    50% {
      background: #00FFFF;
      transform: scale(1);
    }
  }
  @media (min-width: 992px) {
    .accordion-item {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .title-box {
      font-size: 55px;
    }
  }







@import "media"; // Media Query Css End